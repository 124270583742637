import React, { Fragment, useState } from "react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useBreadcrumb } from "gatsby-plugin-breadcrumb"
import { findIconDefinition } from "@fortawesome/fontawesome-svg-core"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"


// Components
import SEO from "../components/Seo"
import LayoutBase from "../components/Layout"
import PageHeader from "../components/PageHeader"
import { Tab, TabList, TabPanel, Tabs } from "react-tabs"


// 登録している Font Awesome Icon を取得する
const falCity = findIconDefinition({ prefix: "fal", iconName: "city" })
const falCar = findIconDefinition({ prefix: "fal", iconName: "car" })
const falBus = findIconDefinition({ prefix: "fal", iconName: "bus" })


// Style
import "../styles/pages/company.scss"
import Img from "gatsby-image"


/**
 * Company Component
 *
 * @param location {any} -
 * @return React Element {*}
 */
interface ICompanyDefaultProps {
    location: any,
}

const Company = (props: ICompanyDefaultProps) => {
    const query = useStaticQuery(graphql`
        query {
            company_pageHeader_backgroundImage: file(relativePath: {eq: "company_pageHeader_backgroundImage.jpg"}) {
                childImageSharp {
                    fluid(maxWidth: 2500, quality: 100) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
            company_universities_img1: file(relativePath: {eq: "company_universities_img1.jpg"}) {
                childImageSharp {
                    fluid(maxHeight: 400, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
            company_universities_img2: file(relativePath: {eq: "company_universities_img2.jpg"}) {
                childImageSharp {
                    fluid(maxHeight: 400, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
            company_universities_img3: file(relativePath: {eq: "company_universities_img3.jpg"}) {
                childImageSharp {
                    fluid(maxHeight: 400, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
            company_universities_img4: file(relativePath: {eq: "company_universities_img4.jpg"}) {
                childImageSharp {
                    fluid(maxHeight: 400, quality: 80) {
                        ...GatsbyImageSharpFluid_withWebp
                        presentationWidth
                    }
                }
            },
        }
    `)
    const pageId = "company"
    const pageTitle = "会社情報"
    const location = props.location
    const { crumbs } = useBreadcrumb({
        location: location,
        crumbLabel: pageTitle,
    })

    return (
        <Fragment>
            <SEO pageTitle={pageTitle}/>
            <LayoutBase
                pageId={pageId}
                crumbs={crumbs}
                location={location}
            >
                <PageHeader
                    title={pageTitle}
                    backgroundImage={query.company_pageHeader_backgroundImage.childImageSharp.fluid}
                    indicators={true}
                />
                <div className="common-content-layout common-content-format">
                    <CompanyVision className="common-content-layout__section"/>
                    <CompanyAbout className="common-content-layout__section"/>
                    <CooperationWithUniversities className="common-content-layout__section" query={query}/>
                    <AccessMap className="common-content-layout__section"/>
                </div>
            </LayoutBase>
        </Fragment>
    )
}


/**
 * ビジョン
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface ICompanyVisionDefaultProps {
    className?: string,
}

const CompanyVision = (props: ICompanyVisionDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="vision" className="grid-x common-content-layout__term">
                <div className="cell">
                    <p>私たちはモノづくりを得意とする会社です。</p>
                    <p>ハードウェア / ソフトウェア / デザイン ― モノを作る上で必要な業務を社内一貫で手掛けています。</p>
                </div>
            </div>
        </div>
    )
}


/**
 * 会社概要
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface ICompanyAboutDefaultProps {
    className?: string,
}

const CompanyAbout = (props: ICompanyAboutDefaultProps) => {
    return (
        <div className={`grid-container ${props.className}`}>
            <div id="about" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h1>会社概要</h1>
                    <table className="hover stack common-table">
                        <tbody>
                        <tr>
                            <th>会社名</th>
                            <td>トランスブート株式会社<span>Tranceboot Co.,Ltd.</span></td>
                        </tr>
                        <tr>
                            <th>設立</th>
                            <td>2009年 1月 20日</td>
                        </tr>
                        <tr>
                            <th>所在地</th>
                            <td>〒520-2123<br/>滋賀県大津市瀬田大江町横谷1番5<br/>TEL: 077-547-5755<br/>FAX: 077-547-5735</td>
                        </tr>
                        <tr>
                            <th>代表者</th>
                            <td>代表取締役 田中 允也</td>
                        </tr>
                        <tr>
                            <th>資本金</th>
                            <td>1,000万円 (2020年1月現在)</td>
                        </tr>
                        <tr>
                            <th>主な業務</th>
                            <td>
                                <b>製品開発（受託・請負開発）</b>
                                <ul>
                                    <li>回路・基板設計</li>
                                    <li>機械・機構設計</li>
                                    <li>ソフトウェア開発</li>
                                    <li>デザイン開発</li>
                                    <li>製作・実装・組立</li>
                                    <li>評価試験</li>
                                    <li>量産支援</li>
                                </ul>
                                <b>自社製品開発</b>
                                <ul>
                                    <li>HEMS制御盤の販売</li>
                                    <li>HEMS制御盤のOEM提供</li>
                                    <li>製品開発</li>
                                </ul>
                            </td>
                        </tr>
                        <tr>
                            <th>加盟団体</th>
                            <td>
                                <ol>
                                    <li>瀬田商工会</li>
                                    <li>エコーネットコンソーシアム</li>
                                </ol>
                            </td>
                        </tr>
                        </tbody>
                    </table>
                </div>
            </div>
        </div>
    )
}


/**
 * アクセスマップ
 *
 * @param className {string} - CSS class name
 * @return {*} React Element
 */
interface IAccessMapDefauotProps {
    className?: string,
}

const AccessMap = (props: IAccessMapDefauotProps) => {
    const [indexTab1, setIndexTab1] = useState(0)
    const [indexTab2, setIndexTab2] = useState(0)

    const handleSelect = (index: any, last: any) => {
        setIndexTab1(index)
        console.log("Selected tab: " + index + ", Last tab: " + last)
    }

    return (
        <div id="accessMap" className={`grid-container ${props.className}`}>
            <div className="grid-x">
                <div className="cell">
                    <h1>アクセスマップ</h1>
                </div>
            </div>
            <div id="accessMap_publicTransport" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h2><FontAwesomeIcon icon={falBus} size="lg" style={{ marginRight: "1rem" }}/>公共交通機関でお越しの場合</h2>
                    <Tabs
                        selectedIndex={indexTab1}
                        onSelect={(index: any, last: any) => setIndexTab1(index)}
                    >
                        <TabList>
                            <Tab>JR - バス</Tab>
                            <Tab>JR - タクシー</Tab>
                        </TabList>
                        <TabPanel>
                            <iframe
                                src="https://www.google.com/maps/d/u/1/embed?mid=1GAP2sZv7AehzPNPO9YyhdUEBf5pUk6aH"></iframe>
                            <div className="access-map-route-information">
                                <ol>
                                    <li>JR-瀬田駅 下車</li>
                                    <li>改札口左手 南口を下ります</li>
                                    <li>３番のりば バスに乗車</li>
                                    <li>龍谷大学 下車</li>
                                    <li>REC棟へは地図内マーカー「徒歩ルート」をご覧ください</li>
                                </ol>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <iframe
                                src="https://www.google.com/maps/d/u/1/embed?mid=1a_y__og44qqgza-0N4ucOa1EMtdLtM5f"></iframe>
                            <div className="access-map-route-information">
                                <ol>
                                    <li>JR-瀬田駅 下車</li>
                                    <li>改札口左手 南口を下ります</li>
                                    <li>タクシー乗り場は南口階段付近にあります</li>
                                    <li>行き先は「龍谷大学REC（レック）棟まで」とお伝えください</li>
                                </ol>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
            <div id="accessMap_car" className="grid-x common-content-layout__term">
                <div className="cell">
                    <h2><FontAwesomeIcon icon={falCar} size="lg" style={{ marginRight: "1rem" }}/>お車でお越しの場合</h2>
                    <Tabs
                        selectedIndex={indexTab2}
                        onSelect={(index: any, last: any) => setIndexTab2(index)}
                    >
                        <TabList>
                            <Tab>国道1号線</Tab>
                            <Tab>京滋BP(東京)</Tab>
                            <Tab>京滋BP(大阪)</Tab>
                            <Tab>名神高速(東京)</Tab>
                            <Tab>名神高速(大阪)</Tab>
                        </TabList>
                        <TabPanel>
                            <iframe
                                src="https://www.google.com/maps/d/u/1/embed?mid=1Q6VBrb1EUvHrYYQzGSfewtXsGnUn8gXI"></iframe>
                            <div className="access-map-route-information">
                                <ol>
                                    <li>「瀬田駅口」交差点から「学園通り」へ入ります。</li>
                                    <li>自動車のルートに沿って移動します。</li>
                                    <li>ゲートにて許可証を受け取ってください。</li>
                                </ol>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <iframe
                                src="https://www.google.com/maps/d/u/1/embed?mid=12myEbsyASjzBcfybYCcg1aghyZVJcQsI"></iframe>
                            <div className="access-map-route-information">
                                <ol>
                                    <li>「一里山四丁目」交差点を左折します。</li>
                                    <li>自動車のルートに沿って移動します。</li>
                                    <li>ゲートにて許可証を受け取ってください。</li>
                                </ol>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <iframe
                                src="https://www.google.com/maps/d/u/1/embed?mid=1WAvrIzAZsN4r0AECOF4zXrForPSaKfxp"></iframe>
                            <div className="access-map-route-information">
                                <ol>
                                    <li>「瀬田東料金所」を下ります。</li>
                                    <li>「一里山四丁目」交差点を右折します。</li>
                                    <li>自動車のルートに沿って移動します。</li>
                                    <li>ゲートにて許可証を受け取ってください。</li>
                                </ol>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <iframe
                                src="https://www.google.com/maps/d/u/1/embed?mid=155LHXFzI-xk9OtP9vKIxSefdZyCCOwVd"></iframe>
                            <div className="access-map-route-information">
                                <ol>
                                    <li>「草津田上 I.C.」を下ります。</li>
                                    <li>料金所を出て最初の信号を左折します。「龍谷大学」の案内板が出ています。</li>
                                    <li>「瀬田大江町」交差点を左折します。</li>
                                    <li>自動車のルートに沿って移動します。</li>
                                    <li>ゲートにて許可証を受け取ってください。</li>
                                </ol>
                            </div>
                        </TabPanel>
                        <TabPanel>
                            <iframe
                                src="https://www.google.com/maps/d/u/1/embed?mid=17oSX16ZNi0rpufLwQyYd0zEWMgeLdy-Y"></iframe>
                            <div className="access-map-route-information">
                                <ol>
                                    <li>「瀬田西 I.C.」を下ります。</li>
                                    <li>料金所を出て最初の信号を右折します。「龍谷大学」の案内板が出ています。</li>
                                    <li>自動車のルートに沿って移動します。</li>
                                    <li>ゲートにて許可証を受け取ってください。</li>
                                </ol>
                            </div>
                        </TabPanel>
                    </Tabs>
                </div>
            </div>
        </div>
    )
}


/**
 * 大学との連携
 */
interface ICooperationWithUniversitiesDefaultProps {
    className?: string,
    query: any,
}

const CooperationWithUniversities = (props: ICooperationWithUniversitiesDefaultProps) => {
    return (
        <div id="cooperationWithUniversities" className={`grid-container ${props.className}`}>
            <div className="grid-x">
                <div className="cell">
                    <h1>大学との連携</h1>
                </div>
            </div>
            <div className="grid-x grid-margin-x common-content-layout__term">
                <div className="cell small-12">
                    <h2><FontAwesomeIcon icon={falCity} size="lg" style={{ marginRight: "1rem" }}/>インターンシップ制度</h2>
                </div>
                <div className="cell small-12 medium-3">
                    <Img
                        fluid={props.query.company_universities_img4.childImageSharp.fluid}
                        alt="龍谷大学REC棟イメージ"
                        className="cooperationWithUniversities__activities_img"
                    />
                </div>
                <div className="cell small-12 medium-9">
                    <p>大学発ベンチャーとして、学生の個人技術向上と全体交流を目的としたサークル「<a href="http://sucnetwork.sakura.ne.jp/suc/"
                                                               target="_blank">SUC（スキルアップコミュニケーション）</a>」に協賛しています。</p>
                    <p>SUCはインターンシップを主な活動として、実際の業務の中からさまざまなスキルを習得すると共に、社会人としての知識、マナーなどを身に付けることを目的としています。トランスブートでは学生の自主性が発揮できる環境を構築するとともに、専門的な常識や規定概念に囚われない自由な想像力、発想力を生かせる開発体制を敷いています。</p>
                </div>
                <div className="cell small-12">
                    <div className="cooperationWithUniversities__activities_title utility-margin-small-top utility-margin-small-bottom">
                        <h3>活動風景</h3>
                    </div>
                </div>
                <div className="cell small-12 medium-6 large-4">
                    <div className="grid-container">
                        <div className="grid-x grid-margin-x">
                            <div className="cell small-12">
                                <Img
                                    fluid={props.query.company_universities_img2.childImageSharp.fluid}
                                    alt="はんだ付け勉強会1"
                                    className="cooperationWithUniversities__activities_img"
                                />
                            </div>
                            <div className="cell small-12">
                                <p>2012年度入部した学生部員を対象に、電気・電子回路の製作において基礎とも言えるはんだ付けの勉強会を行いました。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-6 large-4">
                    <div className="grid-container">
                        <div className="grid-x grid-margin-x">
                            <div className="cell small-12">
                                <Img
                                    fluid={props.query.company_universities_img1.childImageSharp.fluid}
                                    alt="はんだ付け勉強会2"
                                    className="cooperationWithUniversities__activities_img"
                                />
                            </div>
                            <div className="cell small-12">
                                <p>製作した回路はコンデンサ・トランジスタ・抵抗・LEDを用いた「非安定マルチバイブレータ」です。
                                    基板の設計から完成まで学生自ら行いました。</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="cell small-12 medium-12 large-4">
                    <div className="grid-container">
                        <div className="grid-x grid-margin-x">
                            <div className="cell small-12 medium-6 large-12">
                                <Img
                                    fluid={props.query.company_universities_img3.childImageSharp.fluid}
                                    alt="はんだ付け勉強会3"
                                    className="cooperationWithUniversities__activities_img"
                                />
                            </div>
                            <div className="cell small-12 medium-6 large-12">
                                <p>コンデンサが充放電を繰り返して発振するので左右のLED1，LED2が交互に点滅します。
                                    抵抗の抵抗値とコンデンサの静電容量を変化させることにより時定数を変化させ、発振の周期を変えることもできます。
                                    普段はソフトウェア関係の作業が多いメンバーですが、回路製作を通してハードウェアの知識・技術を学んでもらいました。</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Company
